/* https://fonts.google.com/ */
.font-link {
    font-family: 'Dancing Script', cursive;
}

.text-in-page {
    font-size: 20px;
}

/* MyNavbar */

.navbar-brand {
    font-family: 'Dancing Script', cursive;
}

.navbar-items {
    font-family: 'Play', sans-serif;
}

.navbar-toggler{
    border-radius: 10px;
}

.navbar-toggler:hover{
    border-radius: 10px;
    background-color:rgb(225, 225, 225);
}

.navbar-back-to-top-btn {
    position: fixed;
    bottom: 9px;
    right: 30px;
    display: none;
    padding: 8px 11px;
    font-size: 15px;
    border-radius: 50%;
    font-family: 'Play', sans-serif;
    background-color:rgb(130, 130, 130);
    border: 1px solid rgb(130, 130, 130);
    color: white;
}

.navbar-back-to-top-btn:hover {
    background-color: white;
    border: 0px solid rgb(130, 130, 130);
    color: black;
}

/* Home */

.home-card {
    width: 22rem;
    padding: 20px 10px;
    border-radius: 15px;
    background-color: rgba(69, 69, 69, 0.522);
    border: 0px solid white;
}

.home-name {
    font-family: 'Dancing Script', cursive;
    color: white;
}

.home-phrase {
    font-family: 'Play', sans-serif;
    color: white;
}

.home-nav {
    font-family: 'Bebas Neue', cursive;
    color: white;
}

.home-nav:hover {
    color: rgb(209, 209, 209);
}

.home-vertical-center {
    background-color: aqua;
    display: flex;
    align-items: center;
}

.home-bg {
    background-image: url("img_bg.jpg");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
