.pswp--my-class {

    /* icon colors */
    --pswp-icon-color: #ffffff;
    --pswp-icon-color-secondary: #333;

    /* background color */
    /*--pswp-bg: red;*/

    /* debug: permanently display preloader */
    /*opacity: 0.85 !important;*/
  }

/* disable counter */
/*
.pswp__counter {
    display: none;
  }
*/

a.a-gallery {
  padding: 5px 5px 5px 5px;
}